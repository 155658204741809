import React, { useRef } from 'react'
import Header from '../../components/Header'
import Footer from '../../components/Footer'
import './home.css'
import Banner from './components/Banner'
import Story from './components/Story'
import Howitworks from './components/Howitworks'
import Features from './components/Features'
import Download from './components/Download'
import Faqs from './components/Faqs'
import Getintouch from './components/Getintouch'

const Home = () => {
  // scroll to section
  const section1Ref = useRef(null);
  const section2Ref = useRef(null);
  const section3Ref = useRef(null);
  return (
       <>
        <Header section1Ref={section1Ref} section2Ref={section2Ref} section3Ref={section3Ref}/>
           <div className='main'>
               <Banner   />
               <div  ref={section1Ref} ><Story/></div> 
               <div  ref={section2Ref}>  <Howitworks id="sec1" /> </div>
               <Features />
               <Download />
               <Faqs />
               <div  ref={section3Ref}>  <Getintouch /></div>
           </div>
        <Footer />
      </>
  )
}

export default Home