import Logo from '../images/logo.png';
import { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { Dialog, DialogPanel } from '@headlessui/react';
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline';

const navigation = [
  { name: 'About', href: '#' },
  { name: 'How It Works', href: '#' },
  { name: 'Contact Us', href: '#' },
 
]



const Header = ({section3Ref, section1Ref,section2Ref}) => {
    const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

    // sticky header
    const [isSticky, setIsSticky] = useState(false);
    useEffect(() => {
      const handleScroll = () => {
        const offset = window.scrollY;
        if (offset > 100) { // Adjust this value according to when you want the header to become sticky
          setIsSticky(true);
        } else {
          setIsSticky(false);
        }
      };
  
      window.addEventListener('scroll', handleScroll);
  
      return () => {
        window.removeEventListener('scroll', handleScroll);
      };
    }, []);

    
    
    const scrollToSection = (ref) => {
      if(ref?.current){
        ref?.current?.scrollIntoView({ behavior: 'smooth' });
        setMobileMenuOpen(false)
      }
    
    };

  return (
        <header className={`fixed ${isSticky ? 'sticky' : ''}  inset-x-0 top-0 z-50`}>
          <nav className="flex items-center p-3  md:p-6 lg:px-8" aria-label="Global">
            <div className="flex">
              <Link href="#" className="-m-1.5 p-1.5">
                <span className="sr-only">Your Company</span>
                <img
                  className="h-8 w-auto"
                  src={Logo}
                  alt=""
                />
              </Link>
            </div>
            <div className="flex lg:hidden ms-auto">
              <button
                type="button"
                className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700"
                onClick={() => setMobileMenuOpen(true)}
              >
                <span className="sr-only">Open main menu</span>
                <Bars3Icon className="h-6 w-6" aria-hidden="true" />
              </button>
            </div>
            <div className="hidden lg:flex lg:gap-x-12 ms-20">
              {/* {navigation.map((item) => (
                <Link key={item.name} to={item.href} className="text-md hover:text-blue-500 font-medium leading-6 text-gray-900">
                  {item.name}
                </Link>
              ))} */}
               <Link onClick={()=>scrollToSection(section1Ref)}  to="#" className="text-md hover:text-blue-500 font-medium leading-6 text-gray-900">
                   About
                </Link>
                <Link onClick={()=>scrollToSection(section2Ref)}  to="#" className="text-md hover:text-blue-500 font-medium leading-6 text-gray-900">
                   How It Works
                </Link>
                <Link  onClick={()=>scrollToSection(section3Ref)} to="#" className="text-md hover:text-blue-500 font-medium leading-6 text-gray-900">
                   Contact Us
                </Link>
            </div>
            
          </nav>
          <Dialog className="lg:hidden" open={mobileMenuOpen} onClose={setMobileMenuOpen}>
            <div className="fixed inset-0 z-50" />
            <DialogPanel className="fixed inset-y-0 right-0 z-50 w-full overflow-y-auto bg-white px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
              <div className="flex items-center justify-between">
                <Link href="#" className="-m-1.5 p-1.5">
                  <span className="sr-only">Your Company</span>
                  <img
                    className="h-8 w-auto"
                    src={Logo}
                    alt=""
                  />
                </Link>
                <button
                  type="button"
                  className="-m-2.5 rounded-md p-2.5 text-gray-700"
                  onClick={() => setMobileMenuOpen(false)}
                >
                  <span className="sr-only">Close menu</span>
                  <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                </button>
              </div>
              <div className="mt-6 flow-root">
                <div className="-my-6 divide-y divide-gray-500/10">
                  <div className="space-y-2 py-6">
                    
                      <Link onClick={()=>scrollToSection(section1Ref)}
                         to="#"
                        className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                      >
                         About
                      </Link>
                      <Link onClick={()=>scrollToSection(section2Ref)}
                         to="#"
                        className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                      >
                         How it Works
                      </Link>
                      <Link onClick={()=>scrollToSection(section3Ref)}
                         to="#"
                        className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                      >
                         Contact us
                      </Link>
                     
                  </div>
                  
                </div>
              </div>
            </DialogPanel>
          </Dialog>
       </header>
  )
}

export default Header