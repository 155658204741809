import React from 'react'
import Bannerimg from '../../../images/banner-img.png'
import Appstoreimg from '../../../images/app-store.png'
import Playstoreimg from '../../../images/play-store.png'
import { Link } from 'react-router-dom'

const Banner = () => {
  return (
    <>
      <section className='pt-28 md:pt-20 home-banner px-4 md:px-0'>
            <div class="container mx-auto">
              <div class="grid md:grid-cols-2 items-center gap-4">
                <div>
                    <h1 className='text-3xl md:text-5xl   md:w-2/4 font-bold'>
                      Discover the 
                      Convenience     
                      of  <br  className='md:hidden' /> Yalla Drive
                    </h1>
                    <p className='pt-6 md:w-1/2 md:text-xl'>
                        YallaDrive communicates clearly and concisely, delivering information 
                        directly without unnecessary details.
                    </p>
                    <div className="flex mt-10">
                        <Link to="" className='me-3'>
                            <img className='w-40' src={Appstoreimg} alt=""  />
                        </Link>
                        <Link to="" className='me-3'>
                            <img  className='w-40' src={Playstoreimg} alt="" />
                        </Link>
                    </div>
                   
                </div>
                <div>
                   <img className='mt-10 md:mt-0' src={Bannerimg} alt="Yalla Drive" />
                </div>
              </div>
            </div>
       </section>
    </>
  )
}

export default Banner