import React from 'react'
import Icon1 from '../../../images/icon-booking.png'
import Icon2 from '../../../images/icon-driver.png'
import Icon3 from '../../../images/icon-lux.png'
import Icon4 from '../../../images/icon-24x7.png'
import Icon5 from '../../../images/icon-rental.png'
import Icon6 from '../../../images/icon-luxurious.png'

const Features = () => {
  return (
    <section  className='fetures-section  '>
          <div className='container mx-auto'>
            <div className='md:w-2/3 mx-auto text-center '>
                <h2 className='text-3xl md:text-4xl text-white'>Check out our key features</h2>
                <p className='text-white mt-3'>Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. Exercitation veniam consequat sunt nostrud amet.</p>
            </div>
            <div className="grid gap-5 grid-cols-2  md:grid-cols-3  mt-16">
                  <div>
                     <div className="box mb-2 lg:p-10">
                            <img src={Icon1} alt="" />
                            <h4 className='text-white md:text-xl mt-4 mb-1'>Its easy to book a ride</h4>
                            <p className='text-white text-sm'>Effortless Booking, Seamless Journeys: Focus on Enjoying Every Moment!</p>
                     </div>
                  </div>
                  <div>
                     <div className="box small p-10">
                            <img src={Icon2} alt="" />
                            <h4 className='text-white md:text-xl mt-4 mb-1'>Hassle-free chauffeur rental</h4>
                            <p className='text-white text-sm'>We are here for you. Our dedicated team is committed in providing excellent support.</p>
                     </div>
                  </div>
                  <div>
                     <div className="box p-10">
                            <img src={Icon3} alt="" />
                            <h4 className='text-white md:text-xl mt-4 mb-1'>It feels like luxurious</h4>
                            <p className='text-white text-sm'>Experience luxury with Yalla Drive. Enjoy elegance and comfort on your journey.</p>
                     </div>
                  </div>
                  <div>
                     <div className="box small p-10">
                            <img src={Icon4} alt="" />
                            <h4 className='text-white md:text-xl mt-4 mb-1'>Its easy to book a ride</h4>
                            <p className='text-white text-sm'>Effortless Booking, Seamless Journeys: Focus on Enjoying Every Moment!</p>
                     </div>
                  </div>
                  <div>
                     <div className="box p-10">
                            <img src={Icon5} alt="" />
                            <h4 className='text-white md:text-xl mt-4 mb-1'>Hassle-free chauffeur rental</h4>
                            <p className='text-white text-sm'>We are here for you. Our dedicated team is committed in providing excellent support.</p>
                     </div>
                  </div>
                  <div>
                     <div className="box small p-10">
                            <img src={Icon6} alt="" />
                            <h4 className='text-white md:text-xl mt-4 mb-1'>It feels like luxurious</h4>
                            <p className='text-white text-sm'>Experience luxury with Yalla Drive. Enjoy elegance and comfort on your journey.</p>
                     </div>
                  </div>
            </div>
         </div>  
    </section>
  )
}

export default Features