import React from 'react'
import { Link } from 'react-router-dom';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';

const Download = () => {
  return (
    <section className='download-section '>
          <div className='container mx-auto'>
               <div className="bg">
                 <Tabs   >
                    <div className="md:flex justify-between">
                        <div className='md:w-2/3'>
                            <h2 className='text-3xl md:text-4xl leading-10 text-white'>Download our app now and the various benefits you will get immediately</h2>
                        </div>
                        <div className='mt-32 md:mt-0'>
                            <TabList>
                                <Tab>iOS</Tab>
                                <Tab>Android</Tab>
                            </TabList>
                        </div>
                    </div>   
                    <div className=' mt-16 md:mt-80'>
                        <TabPanel className="text-center">
                                <Link className='rounded-full me-5 border hover:bg-transparent hover:text-white	 outline-white text-blue-500 font-semibold   p-5 px-10 bg-white'>Download User App</Link>
                                <Link className='rounded-full border outline-white  hover:bg-white hover:text-blue-500 	text-white font-semibold   p-5 px-10'>Download Driver App</Link>
                        </TabPanel>
                        <TabPanel className="text-center">
                                <Link className='rounded-full me-5 border hover:bg-transparent hover:text-white	 outline-white text-blue-500 font-semibold   p-5 px-10 bg-white'>Download User App</Link>
                                <Link className='rounded-full border outline-white  hover:bg-white hover:text-blue-500 	text-white font-semibold   p-5 px-10'>Download Driver App</Link>
                        </TabPanel>
                    </div>

                 </Tabs>
              </div>
         </div>  
    </section>
  )
}

export default Download