import React from 'react'
import Mockup1 from '../../../images/mockup-1.png';
import Mockup2 from '../../../images/mockup-2.png';
import Mockup3 from '../../../images/mockup-3.png';
import Mockup4 from '../../../images/mockup-4.png';
import Line1 from '../../../images/line1.png';
import Line2 from '../../../images/line2.png';

const Howitworks = ({id}) => {
  return (
    <section id={id} className='how-it-works-section'>
         <div className="container mx-auto">
            <div className="grid md:grid-cols-2">
                <div>
                   <h2 className="text-3xl md:text-4xl">How it Works</h2>
                   <p className= "mt-3">Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. Exercitation veniam consequat.</p>
                </div>
            </div>   

            <div className="grid flex items-center  mt-10 md:mt-0 justify-center md:grid-cols-3  ">
                   <div>
                       <h3 className='text-2xl'>Book a Ride</h3>   
                       <p className='text-sm mt-4'>Lorem ipsum dolor sit amet consectetur. Sapien eleifend luctus donec volutpat. Mi sed morbi at nec cursus lacus leo nulla montes.</p>
                  </div> 
                  <div className='line hidden md:block'>
                    <img src={Line1} className='mx-auto w-1/2' alt="" />
                  </div>
                  <div className='ms-auto'>
                     <img className='mt-5 md:mt-0' src={Mockup1} alt="" />
                  </div>
                  <div className='line md:hidden'>
                    <img src={Line1} className='mx-auto w-1/2' alt="" />
                  </div>
            </div>  

            <div className="grid flex  step-all items-center  justify-center md:grid-cols-3  ">
                  <div className='ms-auto order-2 md:order-1'>
                     <img className='mt-5 md:mt-0' src={Mockup2} alt="" />
                  </div>
                  <div className='line order-2 hidden md:block'>
                    <img src={Line2} className='mx-auto w-1/2' alt="" />
                  </div>
                   <div className='order-1 md:order-3'>
                       <h3 className='text-2xl'>Request a Driver</h3>   
                       <p className='text-sm mt-4'>Lorem ipsum dolor sit amet consectetur. Elementum etiam sit vitae tempor luctus ultricies. Urna quam nisi tellus a in iaculis.</p>
                  </div> 
                  <div className='line md:hidden order-3'>
                    <img src={Line2} className='mx-auto w-1/2' alt="" />
                  </div>
            </div>   

            <div className="grid flex step-all  items-center  justify-center md:grid-cols-3 ">
                   <div>
                       <h3 className='text-2xl'>Connect with Driver</h3>   
                       <p className='text-sm mt-4'>Lorem ipsum dolor sit amet consectetur. Tellus dis massa arcu in enim congue elit. Posuere nulla ac pharetra convallis cras gravida non.</p>
                  </div> 
                  <div className='line hidden md:block'>
                    <img src={Line1} className='mx-auto w-1/2' alt="" />
                  </div>
                  <div className='ms-auto'>
                     <img src={Mockup3} alt="" />
                  </div>
                  <div className='line md:hidden'>
                    <img src={Line1} className='mx-auto w-1/2' alt="" />
                  </div>
            </div>  

            <div className="grid flex step-all  items-center  justify-center md:grid-cols-3  ">
                  <div className='ms-auto order-2 md:order-1'>
                     <img className='mt-5 md:mt-0' src={Mockup4} alt="" />
                  </div>
                  <div className='line order-2'>
                     
                  </div>
                   <div className='order-1 md:order-3'>
                       <h3 className='text-2xl'>Complete your Journey</h3>   
                       <p className='text-sm mt-4'>Lorem ipsum dolor sit amet consectetur. Elementum etiam sit vitae tempor luctus ultricies. Urna quam nisi tellus a in iaculis.</p>
                  </div> 
            </div>   

        </div>     
    </section>
  )
}

export default Howitworks;