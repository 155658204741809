import React from 'react'
import Image from '../../../images/Container.png'
import Logo from '../../../images/logo.png'

const Getintouch = () => {
  return (
    <div className='getintouch-section'>
          <div className="container mx-auto">
            <div className="grid flex items-center md:grid-cols-2">
                <div className='md:w-11/12'>
                    <img src={Image} alt="" />
                </div>  
                <div className='enq-form  ms-6 pt-5  md:pt-0 md:ms-auto md:w-5/6' >
                   <img className='w-32' src={Logo} alt="" />
                    <h2 className='text-3xl md:text-4xl mt-5 mb-2 font-bold'>Get in touch today</h2>
                    <p className='text-sm text-gray-400'>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse varius enim in eros elementum tristique.</p>

                    <div className="form mt-10">
                        <input type="text" placeholder='Name' className='mb-7 rounded-full border-0 bg-gray-50 border border-gray-400 p-4 px-5 w-full  ' />
                        <input type="text" placeholder='Email' className='mb-7 rounded-full border-0 bg-gray-50 border border-gray-400 p-4 px-5 w-full  ' />
                        <textarea name=""  rows="4"  placeholder='Please type your message here...' className='mb-5 rounded-3xl border-0 bg-gray-50 border border-gray-400 p-4 px-5 w-full  '></textarea>

                        <button className='bg-blue-500 text-white px-5 mt-5 py-3 rounded-full'>Send Message</button>
                        
                    </div>
                </div>
            </div>
          </div>  
    </div>
  )
}

export default Getintouch