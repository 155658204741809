import React from 'react'

const Story = () => {
  return (
    <section className='story-section flex items-center'>
          <div className='container mx-auto'>
                <div className="grid md:grid-cols-3">
                    <div>
                        <h2 className='text-4xl text-white mb-5'>Our Story</h2>
                        <p className='text-white'>Gone are the days of squandering precious moments on mundane car-related tasks. With Yalla, bid farewell to the hassles of driving, parking, and navigating traffic. Instead, entrust us with the responsibility while you immerse yourself in what truly matters – whether it's seizing opportunities, cherishing moments with loved ones, or simply indulging in some well-deserved relaxation.</p>
                    </div>
                    
                </div>   
         </div>  
    </section>
  )
}

export default Story