import React from 'react'
import Google from '../images/google-play-store.png'
import Appstore from '../images/ios-app-store.png'
import { Link } from 'react-router-dom'

const Footer = () => {
  return (
    <footer className='px-3 md:px-0'>
        <div className="container mx-auto">
            <div className="grid grid-cols-1">
                <h3 className='text-white text-2xl text-center  '>Turning Stress into easy and effective mobility</h3>
            </div>

            <div className="md:flex  clear-both  gap-10 pt-8">
                <div  className='w-full md:w-2/3' >
                    <h4 className='text-white text-xl mb-2'>About Yalla Drive</h4>
                    <p className='text-white text-sm md:w-2/3 '>"At Yalla Drive, we're committed to providing hassle-free chauffeur rental experiences. With our user-friendly booking platform, we make it easy for you to find the perfect ride for your needs."</p>
                    <div className="flex pt-8">
                      <Link to="">  <img src={Google} className="w-4/5" alt="" /></Link>
                      <Link to=""><img src={Appstore} className="w-4/5" alt="" /></Link>
                    </div>
                </div>
                <div className='float-left pt-8 md:pt-0 w-1/2'>
                    <h4 className='text-white text-xl mb-3'>Company</h4>
                    <ul className='text-white text-sm'>
                        <li><Link to="">About us</Link></li>
                        <li><Link to="">Careers</Link></li>
                        <li><Link to="">Blog</Link></li>
                        <li><Link to="">Contact</Link></li>
                    </ul>
                </div>
                <div  className=' float-left pt-8 md:pt-0 w-1/2  ms-auto '>
                    <h4 className='text-white text-xl mb-3'>Help</h4>
                    <ul className='text-white text-sm'>
                        <li><Link to="">Customer Support</Link></li>
                        <li><Link to="">Privacy</Link></li>
                    </ul>
                </div>
                <div className="clear-both"></div>
            </div>

            <div className="grid grid-cols-1 mt-10 md:mt-20">
                <p className='text-white text-sm text-gray-400 text-center mb-0  '>© 2023 Yalla Drive. All rights reserved.</p>
            </div>


        </div>
    </footer>
  )
}

export default Footer